import { FaFacebook, FaInstagram } from 'react-icons/fa';

const Footer = (props) => {
  return (
    <>
      <div className="mainMainFooter">
        <div className="mainFooter">
          <div className="logo">
            <img src="/inc/dziarczyncy-logo.png" width="180px" className="logo" />
          </div>
          <div className="footerIconsBox">
            <a href="https://www.facebook.com/dziarczyncy/" target="_blank"><FaFacebook className="footerIcon" /></a>
            <a href="https://www.instagram.com/dziarczyncy/" target="_blank"><FaInstagram className="footerIcon" /></a>
          </div>
          <div className="contactInfo">
            <span>Zamkowa 5/2</span>
            <span>61-749 Poznań</span>
            <span>+48 577 995 300</span>
          </div>
        </div>
        <div className="realizacja">
          <span>Realizacja: <a href="https://www.monstercode.pl" target="_blank"><b>Monster Code</b></a></span>
        </div>
      </div>
    </>
  );
};

export default Footer;